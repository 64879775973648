import { getNonLocalizedPath } from "@/lib/utils/i18n";
import * as Sentry from "@sentry/astro";

console.debug("Init Sentry: client");
Sentry.init({
  enabled: import.meta.env.PROD,
  debug: false,
  dsn: import.meta.env.PUBLIC_SENTRY_DSN,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    // don't mask text or block media
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    // remove localization from transaction names
    Sentry.browserTracingIntegration({
      beforeStartSpan: (opt) => ({
        ...opt,
        name: getNonLocalizedPath(window.location.pathname),
      }),
    }),
  ],
});
